<template>
  <div :class="[$style.VInput, mods]">
    <div :class="$style.wrapper" @click.self="autoFocus">
      <span v-if="leadingIcon && $slots.leading" :class="$style.iconAppend">
        <slot name="leading" />
      </span>
      <input
        :id="id"
        ref="input"
        v-model="lazyValue"
        :name="name"
        :type="type"
        :required="required"
        :placeholder="placeholder"
        :disabled="isDisabled"
        :class="$style.input"
        v-bind="$attrs"
        @input="onInput"
        @blur="onBlur"
        @focus="onFocus"
      />
      <slot />
      <span
        v-if="trailingIcon && $slots.trailing"
        :class="$style.icon"
        @click="emit('icon')"
      >
        <slot name="trailing" />
      </span>
    </div>
    <transition name="expand">
      <span v-if="isErrored" :class="$style.errorLabel">
        {{ error }}
      </span>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { addMask, getNextCursorPosition, setSelection } from "~/utils/mask";

type InputSize = "default" | "small";

type InputColor = "primary";

interface VInputProps {
  modelValue: string | number;
  type?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
  autofocus?: boolean;
  autofocusDelay?: number;
  size?: InputSize;
  color?: InputColor;
  error?: string;
  leadingIcon?: boolean;
  trailingIcon?: boolean;
  mask?: string;
}

const props = withDefaults(defineProps<VInputProps>(), {
  type: "text",
  id: "",
  name: "",
  placeholder: "",
  required: false,
  disabled: false,
  loading: false,
  autofocus: false,
  autofocusDelay: 100,
  size: "default",
  color: "primary",
  error: "",
  mask: "",
});

const isDisabled = computed(() => props.disabled || props.loading);
const isErrored = computed(() => !!props.error);
const emit = defineEmits(["update:modelValue", "blur", "icon"]);
const input = ref<HTMLInputElement | null>(null);

const autoFocus = () => {
  input.value?.focus();
};
const isFocused = ref(false);
const onFocus = () => {
  isFocused.value = true;
};

const lazyValue = ref(props.modelValue);

const currentMask = computed(() => {
  switch (props.mask) {
    case "card":
      return "#### #### #### #### ###";
    case "cardExpiryDate":
      return "##/##";
    default: {
      return null;
    }
  }
});
const onInput = (event: InputEvent) => {
  // emit("update:modelValue", (event.target as HTMLInputElement).value);
  if (props.mask && currentMask.value) {
    const target = event.target as HTMLInputElement;
    let endPos = target.selectionEnd || 0;
    const oldValue = lazyValue.value;
    // if (!currentMask.value) {
    //   lazyValue.value = target.value;
    //   return;
    // }
    const newValue = addMask(lazyValue.value.toString(), currentMask.value);
    endPos = getNextCursorPosition(endPos, oldValue.toString(), newValue, " ");
    lazyValue.value = newValue;
    nextTick(() => {
      setSelection(target, endPos);
    });
  }
  emit("update:modelValue", lazyValue.value);
};
const onBlur = (event: FocusEvent) => {
  emit("blur", event);
  isFocused.value = false;
};

onMounted(() => {
  setTimeout(() => {
    if (props.autofocus) {
      autoFocus();
    }
  }, props.autofocusDelay);
});

const style = useCssModule();

const mods = computed(() => ({
  [style[props.size]]: true,
  [style[props.color]]: true,
  [style.disabled]: props.disabled,
  [style.loading]: props.loading,
  [style.errored]: isErrored.value,
  [style.focused]: isFocused.value,
}));

watch(
  () => props.modelValue,
  (value) => {
    lazyValue.value = value;
  },
);
</script>

<style lang="scss" module>
.VInput {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  transition: all $base-transition;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: text;
  border: 1px solid transparent;
  transition: all $base-transition;
}

.default {
  .wrapper {
    height: 6rem;
    padding: 0 2rem;
    border-radius: $border-radius-s;

    @include respond-to(xs) {
      height: 5rem;
      font-size: 14px;
    }
  }
}

.small {
  .wrapper {
    height: 4rem;
    padding: 0 1rem;
    border-radius: $border-radius-xs;
  }
}

.primary {
  .wrapper {
    background-color: var(--primary-block-bg);
  }

  &:hover {
    .wrapper {
      background-color: var(--primary-block-bg-hover);
    }
  }

  .input {
    &::placeholder {
      color: var(--text-color-6);
    }
  }

  &.focused {
    .wrapper {
      background-color: var(--primary-block-bg);
    }
  }
}

.input {
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: inherit;
  color: inherit;
  border: none;
  outline: none;
  font-size: inherit;
  line-height: 1;
  font-weight: 400;

  &::placeholder {
    color: currentcolor;
  }
}

.errored {
  .wrapper {
    border-color: $brand-red;
  }
}

.errorLabel {
  margin-top: 0.5rem;
  font-size: 16px;
  color: $brand-red;
}

.iconAppend {
  width: 2rem;
  height: 2rem;
}

.icon {
  width: 2.4rem;
  height: 2.4rem;
  color: $brand-blue;
  cursor: pointer;
}
</style>
